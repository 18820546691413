import React from "react";
import useRedux from "redux/useRedux";
import styled from "styled-components";
import MainLayout from "components/Layouts/MainLayout";
import NoPhoto from "assets/images/no-product-photo.svg";
import ConfirmationModal from "components/Confirmation";
import CartItem, { ICartItemAddons } from "./cart.item";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "assets/icons";
import { Box, Grid, Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { Button } from "components/styled/button.styled";
import { YellowCircleCheckIcon, BillConfirmationIcon } from "assets/icons";
import {
  getTotalProduct,
  changeQuantity,
  IChangeQuantity,
} from "redux/cart/cart.reducers";

const CartPage = () => {
  const navigate = useNavigate();
  const {
    storeState: { Cart },
    thunkDispatch,
  } = useRedux();
  const [remove, setRemove] = React.useState(false);
  const [removeSuccess, setRemoveSuccess] = React.useState(false);
  const [removeId, setRemoveId] = React.useState("");

  const handleChangeQuantity = (param: IChangeQuantity) => {
    thunkDispatch(changeQuantity(param))
      .unwrap()
      .then(() => {
        if (param.type === "remove") {
          setRemove(false);
          setRemoveSuccess(true);
        }
      });
  };

  React.useEffect(() => {
    thunkDispatch(getTotalProduct()).unwrap();
  }, [Cart, thunkDispatch]);

  return (
    <MainLayout hideBackgroundImage>
      <ConfirmationModal
        open={remove}
        icon={BillConfirmationIcon}
        titleStyle={{ margin: "15px 0" }}
        title={"Apakah anda yakin akan membatalkan item?"}
        handleClose={() => setRemove(false)}
        handleConfirm={() =>
          handleChangeQuantity({
            cart_id: removeId ?? "",
            type: "remove",
          })
        }
      />
      <ConfirmationModal
        open={removeSuccess}
        icon={YellowCircleCheckIcon}
        titleStyle={{ margin: "15px 0" }}
        title={"Item pesanan berhasil di batalkan"}
        handleClose={() => setRemoveSuccess(false)}
        handleConfirm={() => null}
        noAction
      />
      <Box width={"100%"}>
        <Box width={"100%"} margin={"15px 16px"}>
          <Grid container>
            <div onClick={() => navigate("/product")} style={{ width: "10%" }}>
              <img
                src={ArrowLeftIcon}
                alt={"arrow_left_icon"}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                width: "90%",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <NormalText
                fontWeight="bold"
                fontSize={"20px"}
                textAlign={"center"}
                margin={"0 0 0 -70px"}
              >
                Keranjang
              </NormalText>
            </div>
          </Grid>
        </Box>
        <ContainerProduct>
          {Cart && Cart.items && Cart.items.length > 0 ? (
            Cart.items.map((cart, index) => (
              <CartItem
                key={index}
                category={cart.category_name}
                name={cart.product_name}
                image={
                  cart.product_image.length === 0 ? NoPhoto : cart.product_image
                }
                price={cart.price}
                qty={cart.qty}
                onDecrease={() => {
                  if (cart.qty === 1) {
                    setRemoveId(cart.cart_id ?? "");
                    setRemove(true);
                  } else {
                    handleChangeQuantity({
                      cart_id: cart.cart_id ?? "",
                      type: "decrease",
                    });
                  }
                }}
                onIncrease={() =>
                  handleChangeQuantity({
                    cart_id: cart.cart_id ?? "",
                    type: "increase",
                  })
                }
                onRemove={() => {
                  setRemoveId(cart.cart_id ?? "");
                  setRemove(true);
                }}
                additional={cart.additionals as ICartItemAddons[]}
              />
            ))
          ) : (
            <Box
              sx={{
                height: "calc(100vh - 280px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack>
                <NormalText
                  textAlign="center"
                  fontWeight="bold"
                  fontSize={"16px"}
                >
                  Oops!
                </NormalText>
                <NormalText textAlign="center" fontSize="12px">
                  Belum ada produk dikeranjang
                </NormalText>
              </Stack>
            </Box>
          )}
        </ContainerProduct>
      </Box>
      <CartBox>
        <Button
          width={"100%"}
          variant={Cart && Cart.total_product > 0 ? "primary" : "secondary"}
          margin={"10px 0"}
          borderRadius={"8px"}
          style={{ letterSpacing: "0.5px" }}
          onClick={() =>
            Cart && Cart.total_product > 0
              ? navigate("/checkout")
              : navigate("/product")
          }
        >
          {Cart && Cart.total_product > 0 ? "Checkout" : "Kembali"}
        </Button>
      </CartBox>
    </MainLayout>
  );
};

const CartBox = styled(Box)`
  background: white;
  position: absolute;
  padding: 0 20px;
  bottom: 0;
  width: 100%;
  z-index: 2;

  @media only screen and (max-width: 1024px) {
    bottom: 70px;
  }
`;

const ContainerProduct = styled(Box)`
  margin: 10px 0 0 0;
  padding: 0 15px;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 120px);
  @media only screen and (min-width: 1024px) {
    height: calc(100vh - 145px);
  }
`;

export default CartPage;
