// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment from "moment";
import styled from "styled-components";
import PendingList from "./pending.list";
import MainLayout from "components/Layouts/MainLayout";
import useRedux from "redux/useRedux";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { NormalText } from "components/styled/text.styled";
import { ContentContainer } from "components/styled/layout.styled";
import { Box, Grid, Skeleton, Stack, Avatar } from "@mui/material";
import {
  TutupKasirIcon,
  CancelIcon,
  PaidIcon,
  WaitingIcon,
  OrderIcon,
} from "assets/icons";

import {
  getDashboardInfo,
  DashboardInfoData,
  getTotalCashDashboard,
  TotalCashDataProps,
} from "redux/dashboard";
import { storeInfo } from "redux/storeinfo";
import { getSession } from "redux/session";
import { formatCurrency } from "helper/general";
import { selectStore } from "redux/appOption/appoption.reducer";

interface StoreIdProps {
  store_id?: number;
  store_name?: string;
}

const HomePage = () => {
  const [dataDashboard, setDataDashboard] = useState<DashboardInfoData>();
  const [totalCash, setTotalCash] = useState<TotalCashDataProps>();
  const [loading, setLoading] = useState(false);
  const [newOrder, setNewOrder] = useState(false);
  const [orderLoading, setOrderLoading] = useState(true);
  const [showPendingList, setShowPendingList] = useState(false);
  const {
    thunkDispatch,
    storeState: {
      Auth,
      AppOption,
      Profile,
      Notification: { notifications },
    },
  } = useRedux();
  const [storeNameId, setStoreNameId] = useState<StoreIdProps>();
  const navigate = useNavigate();

  const getInfoDashboard = useCallback(
    (id: any) => {
      const findStoreId =
        Auth.data &&
        Auth.data.profile_data &&
        Auth.data.profile_data.store_access &&
        Auth.data.profile_data.store_access.data_store &&
        Auth.data.profile_data.store_access.data_store.length !== 0
          ? id
            ? Auth.data.profile_data.store_access.data_store.find(
                (element: any) => element.store_id === id
              )
            : { store_id: 0, store_name: "" }
          : { store_id: 0, store_name: "" };
      setStoreNameId(findStoreId);
      let dataCash = {
        token: Auth?.data?.access_token ?? "",
        store_id: id ?? "",
      };
      // setOrderLoading(true);
      thunkDispatch(getDashboardInfo(dataCash))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            setLoading(false);
            if (res.data && res.data.data) {
              setDataDashboard(res.data.data);
              setOrderLoading(false);
            }
          } else if (res && res.status === "error") {
            setLoading(false);
          }
        });
      thunkDispatch(getTotalCashDashboard(dataCash))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setLoading(false);
            if (res.data && res.data.data) {
              setTotalCash(res.data);
            }
          } else if (res && res.status === "error") {
            setLoading(false);
          }
        });
    },
    [
      Auth.data,
      thunkDispatch,
      setStoreNameId,
      setOrderLoading,
      setLoading,
      setDataDashboard,
      setTotalCash,
    ]
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (AppOption && AppOption.selected_store && AppOption.selected_store.id) {
      getInfoDashboard(AppOption.selected_store.id);

      intervalId = setInterval(() => {
        getInfoDashboard(AppOption.selected_store.id);
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [AppOption.selected_store.id, getInfoDashboard]);

  const getSessionLoginDash = () => {
    let data = {
      token: Auth?.data?.access_token ?? "",
    };
    thunkDispatch(getSession(data))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setLoading(false);
          console.log(res);
          let props: { id: number; name: string } = {
            id: res.data.data.store_id,
            name: "",
          };
          //set selected store when value is null
          if (!AppOption.selected_store.id) {
            thunkDispatch(selectStore(props));
          }
          getInfoDashboard(res.data.data.store_id);
        } else if (res && res.status === "error") {
          setLoading(false);
          getInfoDashboard(AppOption?.selected_store?.id ?? "");
        }
      });
  };

  const checkNewOrder = () => {
    if (notifications.count_notif && notifications.count_notif > 0) {
      setNewOrder(true);
    }
  };

  const getStoreInfo = (id: number) => {
    thunkDispatch(storeInfo(id)).unwrap();
  };

  useEffect(() => {
    checkNewOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    getSessionLoginDash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (AppOption && AppOption.selected_store && AppOption.selected_store.id) {
      getStoreInfo(AppOption.selected_store.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppOption.selected_store.id]);

  return (
    <MainLayout background="#FFC535" hasNavbar>
      <Box width={"100%"}>
        <StyledBox>
          <StyledContainter>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div style={{ flexDirection: "column" }}>
                <NormalText
                  textAlign={"center"}
                  fontSize={"20px"}
                  fontWeight={"bold"}
                  lineHeight={"24px"}
                  margin={"0px 0 10px 20px"}
                  color={"#153E45"}
                >
                  Hi,{" "}
                  {Profile &&
                  Profile.data &&
                  Profile.data.data &&
                  Profile.data.data.full_name
                    ? Profile.data.data.full_name.length > 15
                      ? Profile.data.data.full_name.substr(0, 15) + ".."
                      : Profile.data.data.full_name
                    : Auth?.data?.profile_data?.full_name
                    ? Auth?.data?.profile_data?.full_name.length > 15
                      ? Auth.data.profile_data.full_name.substr(0, 15) + ".."
                      : Auth?.data?.profile_data?.full_name
                    : ""}
                </NormalText>
                <NormalText
                  textAlign={"center"}
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  margin={"0px 0 40px 20px"}
                  color={"#153E45"}
                >
                  Kasir | {storeNameId ? storeNameId.store_name : ""}
                </NormalText>
              </div>
            </div>
            <Box
              width={"100%"}
              sx={{
                backgroundColor: "white",
                padding: "0 10px",
                boxShadow: "0px 4px 4px rgba(167, 167, 167, 0.5)",
                borderRadius: "10px",
                fontFamily: "D-DIN",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                divider={
                  <div
                    style={{
                      border: "1px solid rgb(222 222 222 / 85%)",
                      height: "50px",
                      marginTop: "12px",
                      marginBottom: "12px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  ></div>
                }
              >
                <Box width={"80%"}>
                  <NormalText fontSize={"14px"} lineHeight={"24px"}>
                    Tanggal
                  </NormalText>
                  <NormalText
                    fontSize={"16px"}
                    fontWeight={"bold"}
                    lineHeight={"24px"}
                  >
                    {moment().format("DD/MM/YYYY")}
                  </NormalText>
                </Box>
                <Box width={"100%"}>
                  <NormalText fontSize={"14px"} lineHeight={"24px"}>
                    Cash POS
                  </NormalText>
                  <NormalText
                    fontSize={"16px"}
                    fontWeight={"bold"}
                    lineHeight={"24px"}
                  >
                    {formatCurrency(
                      Number(totalCash?.data?.total_amount_open_cash ?? 0)
                    )}
                  </NormalText>
                </Box>
                <Box width={"80%"}>
                  <NormalText
                    fontSize={"14px"}
                    textAlign={"center"}
                    lineHeight={"24px"}
                  >
                    Tutup Kasir
                  </NormalText>
                  <div
                    style={{ textAlign: "center" }}
                    onClick={() => navigate("/closing-cashier")}
                  >
                    <img
                      style={{ width: "25px", cursor: "pointer" }}
                      src={TutupKasirIcon}
                      alt={"tutup_kasir"}
                    />
                  </div>
                </Box>
              </Stack>
            </Box>
          </StyledContainter>
        </StyledBox>
        <ContentContainer>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={6}>
              <Card width={"100%"} newOrder={newOrder}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-star"
                  alignItems="flex-star"
                  sx={{ marginLeft: "10px" }}
                >
                  <img src={OrderIcon} alt={"order_icon"} />
                  <NormalText
                    fontSize="12px"
                    fontWeight="bold"
                    letterSpacing="3px"
                    lineHeight="32px"
                    color={"#aaa"}
                    textAlign="center"
                    margin="2px 0px 0 10px"
                  >
                    ORDER
                  </NormalText>
                  {newOrder && (
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: "#FF7676",
                        margin: "9px 0 0 25px",
                      }}
                    >
                      <NormalText fontSize="20px">i</NormalText>
                    </Avatar>
                  )}
                </Grid>
                {!orderLoading ? (
                  <NormalText
                    fontSize="50px"
                    fontWeight="bold"
                    letterSpacing="3px"
                    lineHeight="32px"
                    color={"black"}
                    textAlign="left"
                    margin="30px 0 0 10px"
                  >
                    {dataDashboard?.total_order ?? 0}
                  </NormalText>
                ) : (
                  <Skeleton
                    variant={"rectangular"}
                    width={"80%"}
                    sx={{
                      width: "50%",
                      height: "60px",
                      borderRadius: "10px",
                      margin: "20px 0 0 10px",
                    }}
                  />
                )}
                <NormalText
                  fontSize="12px"
                  fontWeight="bold"
                  letterSpacing="3px"
                  lineHeight="32px"
                  color={"black"}
                  textAlign="left"
                  margin="10px 0 0 10px"
                >
                  PESANAN
                </NormalText>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                width={"100%"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (dataDashboard?.total_order_waiting_payment !== 0) {
                    setShowPendingList(true);
                  }
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-star"
                  alignItems="flex-star"
                  sx={{ marginLeft: "10px" }}
                >
                  <img src={WaitingIcon} alt={"waiting_icon"} />
                  <NormalText
                    fontSize="11px"
                    fontWeight="bold"
                    letterSpacing="2px"
                    lineHeight="32px"
                    color={"#aaa"}
                    textAlign="center"
                    margin="2px 0px 0px 10px"
                    wordWrap="break-word"
                    textJustify="auto"
                  >
                    WAITING
                  </NormalText>
                </Grid>
                {!orderLoading ? (
                  <NormalText
                    fontSize="50px"
                    fontWeight="bold"
                    letterSpacing="3px"
                    lineHeight="32px"
                    color={"black"}
                    textAlign="left"
                    margin="30px 0 0 10px"
                  >
                    {dataDashboard?.total_order_waiting_payment ?? 0}
                  </NormalText>
                ) : (
                  <Skeleton
                    variant={"rectangular"}
                    width={"80%"}
                    sx={{
                      width: "50%",
                      height: "60px",
                      borderRadius: "10px",
                      margin: "20px 0 0 10px",
                    }}
                  />
                )}
                <NormalText
                  fontSize="12px"
                  fontWeight="bold"
                  letterSpacing="3px"
                  lineHeight="20px"
                  color={"black"}
                  textAlign="left"
                  margin="15px 0 0 10px"
                  wordWrap={"break-word"}
                >
                  MENUNGGU PEMBAYARAN
                </NormalText>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card width={"100%"}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-star"
                  alignItems="flex-star"
                  sx={{ marginLeft: "10px" }}
                >
                  <img src={PaidIcon} alt={"paid_icon"} />
                  <NormalText
                    fontSize="12px"
                    fontWeight="bold"
                    letterSpacing="3px"
                    lineHeight="32px"
                    color={"#aaa"}
                    textAlign="center"
                    margin="2px 0px 0 10px"
                  >
                    PAID
                  </NormalText>
                </Grid>
                {!orderLoading ? (
                  <NormalText
                    fontSize="50px"
                    fontWeight="bold"
                    letterSpacing="3px"
                    lineHeight="32px"
                    color={"black"}
                    textAlign="left"
                    margin="30px 0 0 10px"
                  >
                    {dataDashboard?.total_order_paid ?? 0}
                  </NormalText>
                ) : (
                  <Skeleton
                    variant={"rectangular"}
                    width={"80%"}
                    sx={{
                      width: "50%",
                      height: "60px",
                      borderRadius: "10px",
                      margin: "20px 0 0 10px",
                    }}
                  />
                )}
                <NormalText
                  fontSize="12px"
                  fontWeight="bold"
                  letterSpacing="3px"
                  lineHeight="32px"
                  color={"black"}
                  textAlign="left"
                  margin="10px 0 0 10px"
                >
                  DIBAYAR
                </NormalText>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card width={"100%"}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-star"
                  alignItems="flex-star"
                  sx={{ marginLeft: "10px" }}
                >
                  <img src={CancelIcon} alt={"cancel_icon"} />
                  <NormalText
                    fontSize="12px"
                    fontWeight="bold"
                    letterSpacing="3px"
                    lineHeight="32px"
                    color={"#aaa"}
                    textAlign="center"
                    margin="2px 0px 0 10px"
                  >
                    CANCELED
                  </NormalText>
                </Grid>
                {!orderLoading ? (
                  <NormalText
                    fontSize="50px"
                    fontWeight="bold"
                    letterSpacing="3px"
                    lineHeight="32px"
                    color={"black"}
                    textAlign="left"
                    margin="30px 0 0 10px"
                  >
                    {dataDashboard?.total_order_cancelled ?? 0}
                  </NormalText>
                ) : (
                  <Skeleton
                    variant={"rectangular"}
                    width={"80%"}
                    sx={{
                      width: "50%",
                      height: "60px",
                      borderRadius: "10px",
                      margin: "20px 0 0 10px",
                    }}
                  />
                )}
                <NormalText
                  fontSize="12px"
                  fontWeight="bold"
                  letterSpacing="3px"
                  lineHeight="20px"
                  color={"black"}
                  textAlign="left"
                  margin="15px 0 0 10px"
                  wordWrap={"break-word"}
                >
                  DIBATALKAN
                </NormalText>
              </Card>
            </Grid>
          </Grid>
        </ContentContainer>
      </Box>
      {showPendingList && (
        <PendingList
          limit={dataDashboard ? dataDashboard.total_order_waiting_payment : 0}
          onClose={() => {
            setShowPendingList(false);
            if (
              AppOption &&
              AppOption.selected_store &&
              AppOption.selected_store.id
            ) {
              getInfoDashboard(AppOption.selected_store.id);
            }
          }}
        />
      )}
    </MainLayout>
  );
};

const StyledBox = styled(Box)`
  height: 250px;
  position: relative;
  width: 100%;
`;

const StyledContainter = styled(Box)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

interface CardProps {
  newOrder?: boolean;
}

const Card = styled(Box)<CardProps>`
  width: 145.57px;
  height: 159.85px;
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  border: ${({ newOrder }) => (newOrder ? "1px solid #FF7676" : "")};
`;

export default HomePage;
