// Prequisite
// Using this component InvoiceData on state must be fullfilled first

import React from "react";
import ConfirmationModal from ".";
import { BillConfirmationIcon } from "assets/icons";
import { Stack } from "@mui/material";
import PrintReceipt from "views/Checkout/Print/print.receipt.components";
import { Button } from "components/styled/button.styled";
import { notify } from "helper/general";
import { useNavigate } from "react-router-dom";
import useRedux from "redux/useRedux";

interface IPrintConfirmation {
  open: boolean;
}

const PrintConfirmation = ({ open }: IPrintConfirmation) => {
  const { handlePrint } = PrintReceipt();
  const {
    storeState: { Invoice },
  } = useRedux();

  const printToWebview = (typePrint: any) => {
    if (window?.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({
        type: typePrint,
        data: Invoice
      }));
    }

    return;
  }

  const navigate = useNavigate()
  return (
    <ConfirmationModal
      icon={BillConfirmationIcon}
      open={open}
      title={"Cetak Struk Pembelian"}
      hasCancel={false}
      confirmText={"Cetak"}
      handleClose={() => null}
      handleConfirm={async () => {
        printToWebview("print-merchant")
        await handlePrint("merchant")
      }}
      useCustomButton={
        <Stack
          direction={"column"}
          width={"100%"}
          spacing={1}
          margin={"10px 0 0 0"}
        >
          <Button
            variant={"primary"}
            onClick={async () => {
              printToWebview("print-customer")
              await handlePrint("customer")
            }}>
            Customer
          </Button>
          <Button
            variant={"primary"}
            onClick={async () => {
              printToWebview("print-merchant")
              await handlePrint("merchant")
            }}
          >
            Merchant
          </Button>
          <Button
            variant={"outline"}
            onClick={() => {
              notify("success", "Transaksi Selesai");
              navigate("/");
            }}
          >
            Selesai
          </Button>
        </Stack>
      }
    />
  );
};

export default PrintConfirmation;
