import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './views/Home/home.page';
import LoginPage from './views/Auth/login.page';
import ForgotPasswordPage from 'views/Auth/forgotpassword.page';
import ResetPasswordPage from 'views/Auth/resetpassword.page';
import NotFoundPage from 'views/Error/notfound';
import ProductPage from 'views/Product/product.page';
import CartPage from 'views/Cart/cart.page';
//profile
import ProfilePage from 'views/Profile';
import UpdateProfilePage from 'views/Profile/update.profile.page';
import UpdatePasswordPage from 'views/Profile/update.password.page';
import CheckoutPage from 'views/Checkout/checkout.page';

//closing cashier
import ClosingCashierPage from 'views/ClosingCashier';

//activated page
import ActivatedPage from 'views/Auth/activated';

//order
import OrderPage from 'views/Order';
import DetailOrderPage from 'views/Order/order.detail.page';

import SuccessPage from 'views/Checkout/success.page';

//implement firebase
import { useState, useEffect } from 'react';
import { fetchToken, onMessageListener } from './firebase';
import { showDetailStore } from 'redux/storeinfo';
import useRedux from 'redux/useRedux';
import Sound from 'assets/sound/notif.mp3';
import { notify, removeAllState } from 'helper/general';
import { updateNotification } from 'redux/notification/notification.reducer';
import ProductListPage from 'views/Product/product.list.page';
import ProductAddPage from 'views/Product/product.add.page';
import ProductEditPage from 'views/Product/product.edit.page';

import CheckPermissionRoute from 'components/PrivateRoute/PermissionRoute';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [show, setShow] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notification, setNotification] = useState({ title: '', body: '' });
  const {
    thunkDispatch,
    storeState: {
      Auth,
      AppOption,
      Notification: { notifications },
    },
  } = useRedux();
  const navigate = useNavigate();

  const getDetailStore = async (id: any) => {
    await thunkDispatch(showDetailStore(id ? Number(id) : 0))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === 'success') {
          let dataDetail = res.data.data;
          sessionStorage.setItem('store_code', dataDetail.store_code);
          sessionStorage.setItem('store_alias', dataDetail.store_alias);
          fetchToken(dataDetail.store_code, 'SUBSCRIBE');
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    //get data from session to unset topic
    let store_code = sessionStorage.getItem('store_code');
    //unsubscribe topic when stay at login page
    if (store_code && window.location.pathname === '/login') {
      fetchToken(store_code, 'UNSUBSCRIBE');
      sessionStorage.clear();
    }

    if (Auth && Auth.data && Auth.data.user_type === 'user_merchant') {
      if (
        AppOption &&
        AppOption.selected_store &&
        AppOption.selected_store.id
      ) {
        getDetailStore(AppOption.selected_store.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Auth, AppOption]);
  
  useEffect(() => {
    if (Object.keys(Auth.data).length === 0) {
      removeAllState();

      navigate('/login');
    }else if (moment(Auth.data.expired_date).subtract(7, 'hours').isBefore()) {
      removeAllState();

      navigate('/login');
    }
  }, [Auth, navigate])

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        console.log(payload);
        setShow(true);
        //play sound and show notify
        new Audio(Sound).play();
        notify(
          'success',
          `${payload.notification.title}, ${payload.notification.body}`
        );

        //show notif red color dot at menu pesanan
        let prop: { count_notif: number; count_new_order: number } = {
          count_notif: notifications.count_notif
            ? notifications.count_notif + 1
            : 1,
          count_new_order: notifications.count_new_order
            ? notifications.count_new_order
            : 0,
        };
        thunkDispatch(updateNotification(prop));
      })
      .catch((err) => console.log('failed: ', err));
  });

  return (
    <Routes>
      <Route path="*" element={<Navigate to={'/404'} replace />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/password-recovery/:id" element={<ResetPasswordPage />} />
      <Route path="/product" element={<ProductPage />} />
      <Route
        path="/product/list"
        element={
          <CheckPermissionRoute
            feature_name="Product"
            permission={'is_view' || 'is_add' || 'is_edit' || 'is_delete'}
          >
            <ProductListPage />
          </CheckPermissionRoute>
        }
      />
      <Route
        path="/product/add"
        element={
          <CheckPermissionRoute feature_name="Product" permission="is_add">
            <ProductAddPage />
          </CheckPermissionRoute>
        }
      />
      <Route
        path="/product/:id/edit"
        element={
          <CheckPermissionRoute feature_name="Product" permission="is_edit">
            <ProductEditPage />
          </CheckPermissionRoute>
        }
      />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/404" element={<NotFoundPage />} />
      {/* profiel */}
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/update-profile" element={<UpdateProfilePage />} />
      <Route path="/update-password" element={<UpdatePasswordPage />} />
      {/* closing cashier */}
      <Route path="/closing-cashier" element={<ClosingCashierPage />} />
      {/* activated page */}
      <Route path="/activation" element={<ActivatedPage />} />
      {/* order */}
      <Route path="/order" element={<OrderPage />} />
      <Route path="/detail-order/:id" element={<DetailOrderPage />} />
      <Route path="/success" element={<SuccessPage />} />
    </Routes>
  );
};

export default App;
