import React from "react";
import styled from "styled-components";
import ProductItem from "./product.item";
import ProductDetail from "./product.detail";
import ProductLoader from "./Loader/item.loader";
import InputSearch from "components/Form/InputSearch";
import MainLayout from "components/Layouts/MainLayout";
import ConfirmationModal from "components/Confirmation";
import NoPhoto from "assets/images/no-product-photo.svg";
import useRedux from "redux/useRedux";
import useDebounce from "hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { notify } from "helper/general";
import { Box, Grid, Stack } from "@mui/material";
import { YellowCircleCheckIcon } from "assets/icons";
import { ArrowLeftIcon, CartIcon } from "assets/icons";
import { Button } from "components/styled/button.styled";
import { NormalText } from "components/styled/text.styled";
import { getCategoryList } from "redux/product";
import {
  getTotalProduct,
  addToCart,
  IProduct,
  IAdditional,
} from "redux/cart/cart.reducers";
import {
  getProductList,
  ProductResponseProps,
  ProductCategoryListInterface,
} from "redux/product";

const ProductPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { Cart, AppOption },
  } = useRedux();
  const [activeCategory, setActiveCategory] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [showDetail, setShowDetail] = React.useState(false);
  const [addSuccess, setAddSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [cartPayload, setCartPayload] = React.useState<IProduct>();
  const [products, setProducts] = React.useState<ProductResponseProps>();
  const [allProducts, setAllProducts] = React.useState<ProductResponseProps>();
  const [categories, setCategories] =
    React.useState<ProductCategoryListInterface[]>();
  const searchText = useDebounce({ value: search, delay: 500 });

  const handleAddToCart = (props: IProduct) => {
    thunkDispatch(addToCart(props))
      .unwrap()
      .then(() => setAddSuccess(true));
  };

  const fetchAllProduct = () => {
    setLoading(true);
    thunkDispatch(
      getProductList({
        store_id: AppOption?.selected_store?.id ?? 0,
        category_id: 0,
        keyword: searchText.toLowerCase(),
        limit: 0,
        offset: 0,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setAllProducts(res.data);
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchProduct = () => {
    setLoading(true);
    thunkDispatch(
      getProductList({
        store_id: AppOption?.selected_store?.id ?? 0,
        category_id: activeCategory,
        keyword: searchText.toLowerCase(),
        limit: 0,
        offset: 0,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setProducts(res.data);
        } else if (res && res.status === "error") {
          if (res.error.response) {
            notify("error", res.error.response.data.message);
          } else {
            notify("error", res.error.message);
          }
        }
      })
      .catch((err) => console.log("asd", err))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    thunkDispatch(getCategoryList())
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setCategories(res.data.data);
        }
      });
  }, [thunkDispatch]);

  React.useEffect(() => {
    fetchAllProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchProduct();
  }, [activeCategory, searchText]); // eslint-disable-line

  React.useEffect(() => {
    thunkDispatch(getTotalProduct()).unwrap();
  }, [Cart, thunkDispatch]);

  return (
    <MainLayout hideBackgroundImage hasNavbar>
      <ConfirmationModal
        open={addSuccess}
        icon={YellowCircleCheckIcon}
        titleStyle={{ margin: "15px 0" }}
        title={"Produk berhasil di tambahkan"}
        handleClose={() => setAddSuccess(false)}
        handleConfirm={() => null}
        noAction
      />
      <Box width={"100%"}>
        <Box width={"100%"} padding={"15px 16px"}>
          <Grid container direction={"row"}>
            <div onClick={() => navigate("/")} style={{ width: "10%" }}>
              <img
                src={ArrowLeftIcon}
                alt={"arrow_left_icon"}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                width: "90%",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <NormalText
                fontWeight="bold"
                fontSize={"20px"}
                textAlign={"center"}
                margin={"0 0 0 -60px"}
              >
                Produk
              </NormalText>
            </div>
          </Grid>
        </Box>
        <Box width={"100%"} padding={"0 15px"} margin={"10px 0 0 0"}>
          <InputSearch
            placeholder="Cari produk pilihanmu"
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </Box>
        <Box margin={"11px 0 0 0"}>
          <Navigation>
            <UnorderList>
              <ListItem
                active={0 === activeCategory}
                onClick={() => setActiveCategory(0)}
              >
                All
              </ListItem>

              {/* show category have product */}
              {categories && allProducts && categories.length > 0
                ? categories
                    .filter((data: any) =>
                      allProducts.data.find(
                        (element) => element.category.id === data.id
                      )
                    )
                    .map((item, index) => (
                      <ListItem
                        key={index}
                        active={item.id === activeCategory}
                        onClick={() => setActiveCategory(item.id)}
                      >
                        {item.subscategory_name}
                      </ListItem>
                    ))
                : null}
            </UnorderList>
          </Navigation>
        </Box>
        <ContainerProduct
          cartExist={
            Cart && Cart.total_product && Cart.total_product > 0 ? true : false
          }
        >
          {loading ? (
            <ProductLoader />
          ) : (
            <>
              <Grid container spacing={1}>
                {products &&
                  products.data &&
                  products.data.map((product) => (
                    <Grid item xs={6} key={product.product_id}>
                      <ProductItem
                        productId={product.product_id}
                        name={product.product_name}
                        price={
                          product.product_price_after_discount &&
                          Number(product.product_price_after_discount) > 0
                            ? Number(product.product_price_after_discount)
                            : Number(product.product_price)
                        }
                        image={
                          product.product_image_main.length === 0
                            ? NoPhoto
                            : product.product_image_main
                        }
                        customize={product.is_customizable}
                        onBoxClick={() => {
                          setCartPayload({
                            category_name: product?.category?.name ?? "",
                            category_id: product?.category?.id ?? "",
                            is_customizable: product.is_customizable ?? false,
                            product_image:
                              product.product_image_main.length === 0
                                ? NoPhoto
                                : product.product_image_main ?? "",
                            product_name: product.product_name ?? "",
                            price:
                              product.product_price_after_discount &&
                              Number(product.product_price_after_discount) > 0
                                ? Number(product.product_price_after_discount)
                                : Number(product.product_price),
                            product_id: product.product_id ?? "",
                            qty: 1,
                            additionals: [],
                          });
                          setShowDetail(true);
                        }}
                        onButtonClick={() => {
                          if (product.is_customizable) {
                            setCartPayload({
                              category_name: product?.category?.name ?? "",
                              category_id: product?.category?.id ?? "",
                              is_customizable: product.is_customizable ?? false,
                              product_image: product.product_image_main ?? "",
                              product_name: product.product_name ?? "",
                              price:
                                product.product_price_after_discount &&
                                Number(product.product_price_after_discount) > 0
                                  ? Number(product.product_price_after_discount)
                                  : Number(product.product_price),
                              product_id: product.product_id ?? "",
                              qty: 1,
                              additionals: [],
                            });
                            setShowDetail(true);
                          } else {
                            handleAddToCart({
                              category_name: product?.category?.name ?? "",
                              category_id: product?.category?.id ?? "",
                              is_customizable: product.is_customizable ?? false,
                              product_image: product.product_image_main ?? "",
                              product_name: product.product_name ?? "",
                              price:
                                product.product_price_after_discount &&
                                Number(product.product_price_after_discount) > 0
                                  ? Number(product.product_price_after_discount)
                                  : Number(product.product_price),
                              product_id: product.product_id ?? "",
                              qty: 1,
                              additionals: [],
                            });
                          }
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
              <Box marginTop={"57px"} />
            </>
          )}

          {!loading && products?.data?.length === 0 && (
            <Box
              sx={{
                height: "calc(100vh - 280px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack>
                <NormalText
                  textAlign="center"
                  fontWeight="bold"
                  fontSize={"16px"}
                >
                  Oops!
                </NormalText>
                <NormalText textAlign="center" fontSize="12px">
                  Tidak ada produk ditemukan{" "}
                  {searchText ? `dengan kata kunci "${searchText}"` : ""}
                </NormalText>
              </Stack>
            </Box>
          )}
        </ContainerProduct>
      </Box>
      {Cart && Cart.items && Cart.items.length > 0 && (
        <CartBox>
          <Button
            width={"100%"}
            variant={"primary"}
            margin={"10px 0 0 0"}
            borderRadius={"8px"}
            style={{ letterSpacing: "0.5px" }}
            onClick={() => navigate("/cart")}
          >
            <Stack
              textAlign={"center"}
              alignItems={"center"}
              direction={"row"}
              justifyContent={"center"}
              spacing={1}
            >
              <img src={CartIcon} alt={"cart_ic"} style={{ width: "19px" }} />
              <div>Cart ({Cart.total_product})</div>
            </Stack>
          </Button>
        </CartBox>
      )}
      {showDetail && (
        <ProductDetail
          productId={cartPayload?.product_id ?? 0}
          name={cartPayload?.product_name ?? ""}
          category={cartPayload?.category_name ?? ""}
          customizable={cartPayload?.is_customizable ?? false}
          price={cartPayload?.price ?? 0}
          image={cartPayload?.product_image ?? ""}
          counterProps={{
            value: cartPayload?.qty,
            onIncrease: () => {
              if (cartPayload && cartPayload.qty) {
                setCartPayload({
                  ...cartPayload,
                  qty: cartPayload.qty + 1,
                });
              }
            },
            onDecrease: () => {
              if (cartPayload && cartPayload.qty) {
                let quantity = cartPayload.qty;
                if (quantity === 1) {
                  setCartPayload({
                    ...cartPayload,
                    qty: 1,
                  });
                } else {
                  setCartPayload({
                    ...cartPayload,
                    qty: cartPayload.qty - 1,
                  });
                }
              }
            },
          }}
          onAdd={(selected: IAdditional[]) => {
            if (cartPayload) {
              handleAddToCart({ ...cartPayload, additionals: selected });
            }
            setShowDetail(false);
          }}
          onClose={() => setShowDetail(false)}
        />
      )}
    </MainLayout>
  );
};

const CartBox = styled(Box)`
  background: white;
  position: absolute;
  padding: 0 20px;
  bottom: 100px;
  width: 100%;
  z-index: 2;

  @media only screen and (min-width: 1024px) {
    bottom: 100px;
  }
`;

interface ContainerProps {
  cartExist?: boolean;
}

const ContainerProduct = styled(Box)<ContainerProps>`
  margin: 10px 0 0 0;
  padding: 0 15px;
  width: 100%;
  overflow-y: auto;
  height: ${(props) =>
    props.cartExist ? "calc(100vh - 335px)" : "calc(100vh - 280px)"};
  @media only screen and (min-width: 1024px) {
    height: ${(props) =>
      props.cartExist ? "calc(100vh - 335px)" : "calc(100vh - 280px)"};
  }
`;

const Navigation = styled.div`
  width: 100%;
  transition: ease-in-out 0.5s;
  margin-bottom: -15px;
  text-align: left;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    width: 360px;
  }
`;

const UnorderList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  width: 100%;
  /* ::-webkit-scrollbar {
    display: none;
  } */
`;

interface ListProps {
  active?: boolean;
  background?: string;
}

const ListItem = styled.li<ListProps>`
  transition: ease-in-out 0.2s;
  display: block;
  max-width: 100%;
  padding: 0 20px;
  margin-right: 5px;
  margin-top: 5px;
  margin-left: 2px;
  font-family: "D-DIN";
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: "black";
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  text-underline-offset: 6px;
  font-size: 12px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  min-width: 80px;

  :hover {
    font-weight: bold;
  }
`;

export default ProductPage;
