import useRedux from 'redux/useRedux';
import EscPosEncoder from '@manhnd/esc-pos-encoder';
import { IInvoice } from 'redux/checkout';
import { BluetoothRemoteGATTCharacteristic } from 'helper/modules/sbx-print/webbluetooth';

interface Column {
  width: number;
  align: 'left' | 'right' | 'center';
  verticalAlign?: 'top' | 'bottom';
  marginLeft?: number;
  marginRight?: number;
}

const InvoiceTableColumn: Column[] = [
  { width: 5, align: 'center' },
  { width: 15, align: 'left' },
  { width: 10, align: 'right' },
];

let printCharacteristic: any = undefined;

const PrintReceipt = () => {
  const {
    storeState: { Invoice },
  } = useRedux();

  const getData = async (
    invoiceData: IInvoice,
    type?: 'customer' | 'merchant'
  ) => {
    let invoiceEncoder = new EscPosEncoder();
    let basePrint = invoiceEncoder.newline().newline();
    basePrint
      .align('center')
      .line(invoiceData.store_name)
      .line(invoiceData.store_address)
      .newline()
      .align('left')
      .line(`No Trx   : ${invoiceData.order_number}`)
      .line(`Kasir    : ${invoiceData.cashier_name ?? '-'}`)
      .line(
        `Customer : ${
          invoiceData.customer_name ? invoiceData.customer_name : 'Guest'
        }`
      )
      .line(`Tanggal  : ${invoiceData.order_date}`)
      .line(`Jam      : ${invoiceData.order_time}`)
      .newline();
    let items = [['QTY', 'ITEM', 'TOTAL']];

    invoiceData.order_detail.forEach((item) => {
      items.push([String(item.qty), item.product_name, item.total]);
      if (item.order_detail_add_on && item.order_detail_add_on.length > 0) {
        item.order_detail_add_on.forEach((addon) => {
          items.push(['', addon.product_name, '']);
        });
      }
    });

    basePrint.table(InvoiceTableColumn, items).newline();
    if (invoiceData.payment_fee_type === 'OWNER') {
      if(Number(invoiceData.tax) === 0 && Number(invoiceData.discount_amount) === 0 && Number(invoiceData.service_fee) === 0) {

        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
  
      } else if (Number(invoiceData.tax) === 0 && Number(invoiceData.discount_amount) === 0 && Number(invoiceData.service_fee) !== 0) {
        
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          .line(
            `Service   : ${
              Number(invoiceData.service_fee)
            }`
          )
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      } else if (Number(invoiceData.tax) !== 0 && Number(invoiceData.discount_amount) === 0 && Number(invoiceData.service_fee) === 0) {
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`Tax       : ${Number(invoiceData.tax)}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      } else if (Number(invoiceData.tax) === 0 && Number(invoiceData.discount_amount) !== 0 && Number(invoiceData.service_fee) === 0) {
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`Discount  : ${Number(invoiceData.discount_amount)}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      } else {
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          .line(
            `Service   : ${
              Number(invoiceData.service_fee)
            }`
          )
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`Tax       : ${Number(invoiceData.tax)}`)
          .line(`Discount  : ${Number(invoiceData.discount_amount)}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      }

    } else {
      if(Number(invoiceData.tax) === 0 && Number(invoiceData.payment_fee_customer) === 0 && Number(invoiceData.discount_amount) === 0 && Number(invoiceData.service_fee) === 0) {

        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
  
      } else if (Number(invoiceData.tax) === 0 && Number(invoiceData.payment_fee_customer) === 0 && Number(invoiceData.discount_amount) === 0 && Number(invoiceData.service_fee) !== 0) {
        
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          .line(
            `Service   : ${
              Number(invoiceData.service_fee)
            }`
          )
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      } else if (Number(invoiceData.tax) === 0 && Number(invoiceData.payment_fee_customer) !== 0 && Number(invoiceData.discount_amount) === 0 && Number(invoiceData.service_fee) === 0) {
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          .line(`Payment Fee : ${Number(invoiceData.payment_fee_customer) }`)
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`Tax       : ${Number(invoiceData.tax)}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      } else if (Number(invoiceData.payment_fee_customer) === 0 && Number(invoiceData.tax) !== 0 && Number(invoiceData.discount_amount) === 0 && Number(invoiceData.service_fee) === 0) {
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`Tax       : ${Number(invoiceData.tax)}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      } else if (Number(invoiceData.payment_fee_customer) === 0 && Number(invoiceData.tax) === 0 && Number(invoiceData.discount_amount) !== 0 && Number(invoiceData.service_fee) === 0) {
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`Discount  : ${Number(invoiceData.discount_amount)}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      } else {
        basePrint
          .line(`Sub total : ${Number(invoiceData.sub_total)}`)
          .line(
            `Service   : ${
              Number(invoiceData.service_fee)
            }`
          )
          .line(`Payment Fee : ${Number(invoiceData.payment_fee_customer) }`)
          // .line(`Platform  : ${parseInt(invoiceData?.sub_total || '0') <= 10000 ?  (parseInt(invoiceData?.sub_total || '0') * 5) / 100 :
          //   parseInt(invoiceData?.sub_total || '0') > 10000 && parseInt(invoiceData?.sub_total || '0') <= 25000 ? 500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 25000 && parseInt(invoiceData?.sub_total || '0') <= 150000 ? 1000 :
          //   parseInt(invoiceData?.sub_total || '0') > 150000 && parseInt(invoiceData?.sub_total || '0') <= 500000 ? 1500 : 
          //   parseInt(invoiceData?.sub_total || '0') > 500000 ? 5000: 0}`)
          .line(`Tax       : ${Number(invoiceData.tax)}`)
          .line(`Discount  : ${Number(invoiceData.discount_amount)}`)
          .line(`TOTAL     : ${Number(invoiceData.total)}`)
          .align('center')
          .newline();
      }
    }


    if (invoiceData.payment_status === 'PAID') {
      basePrint
        .newline()
        .line('Untuk cek pesanan kamu, bisa melakukan scan disini');
    } else {
      basePrint.line('Silahkan lakukan pembayaran dikasir');
    }

    // if (invoiceData.payment_status === 'PAID') {
    //   basePrint
    //     .newline()
    //     .align('center')
    //     .line('Nomor Antrian')
    //     .line(String(invoiceData.order_queue))
    //     .newline()
    //     .line('Untuk cek pesanan kamu, bisa melakukan scan disini');
    // } else {
    //   basePrint.line('Silahkan lakukan pembayaran dikasir');
    // }

    if (invoiceData.order_qr_code) {
      basePrint.qrcode(invoiceData.order_qr_code, 1, 8, 'm');
    }

    basePrint.newline().line('Terima Kasih').newline();

    if (type === 'customer') {
      basePrint.newline().line('COPY FOR CUSTOMER');
    } else {
      basePrint.newline().line('COPY FOR MERCHANT');
    }

    basePrint.newline();

    return basePrint.encode();
  };

  async function sendPrinterData(
    characteristic: BluetoothRemoteGATTCharacteristic,
    type?: 'customer' | 'merchant'
  ) {
    if (Invoice && Invoice.status === 'success') {
      console.log('sendPrinterData');
      let invoiceData = Invoice.data.data;

      let text = await getData(invoiceData, type);
      var maxChunk = 125;

      console.log('text', text);
      if (text.length > maxChunk) {
        let j = 0;
        for (var i = 0; i < text.length; i += maxChunk) {
          var subStr;
          if (i + maxChunk <= text.length) {
            subStr = text.slice(i, i + maxChunk);
          } else {
            subStr = text.slice(i, text.length);
          }

          setTimeout(writeStrToCharacteristic, 250 * j, characteristic, subStr);
          j++;
        }
      } else {
        console.log('ASD', text);
        await characteristic.writeValue(text);
      }
    } else {
      console.log('Invoice Not Found');
    }
  }

  function writeStrToCharacteristic(
    characteristic: BluetoothRemoteGATTCharacteristic,
    str: Uint8Array
  ) {
    let buffer = new ArrayBuffer(str.length);
    let dataView = new DataView(buffer);
    for (var i = 0; i < str.length; i++) {
      dataView.setUint8(i, str.at(i)!);
    }
    return characteristic.writeValue(dataView);
  }

  const getPrintDeviceList = async () => {
    const nvg = navigator as any;
    if (nvg && nvg.bluetooth) {
      return await (nvg.bluetooth as Bluetooth).requestDevice({
        filters: [
          {
            services: ['000018f0-0000-1000-8000-00805f9b34fb'],
          },
        ],
      });
    } else {
      throw new Error('Navigator / Bluetooth is not found!');
    }
  };

  const handlePrint = async (type?: 'customer' | 'merchant') => {
    try {
      const deviceList = await getPrintDeviceList();
      const gatt = await deviceList?.gatt?.connect();
      if (gatt) {
        if (typeof gatt.getPrimaryService === 'function') {
          const service = await gatt.getPrimaryService(
            '000018f0-0000-1000-8000-00805f9b34fb'
          );
          if (service) {
            const characteristic = await service.getCharacteristic(
              '00002af1-0000-1000-8000-00805f9b34fb'
            );
            printCharacteristic = characteristic;
            sendPrinterData(printCharacteristic, type);
          } else {
            console.log('service not found!');
          }
        } else {
          console.log('gatt.getPrimaryService not found!');
        }
      } else {
        console.log('GATT Device not found!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { handlePrint };
};

export default PrintReceipt;
